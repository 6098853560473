import * as React from "react"

import useParties from "@hooks/useParties"

import Layout from "@components/common/Layout"
import Seo from "@components/common/SEO"
import Top from "@components/parties/Top"
import Breadcrumbs from "@components/parties/Breadcrumbs"
import SearchBox from "@components/parties/SearchBox"
import Upcoming from "@components/parties/Upcoming"
import ByDate from "@components/parties/ByDate"
import Area from "@components/parties/Area"
import Service from "@components/parties/Service"
import ByTime from "@components/parties/ByTime"

const Parties = () => {
  const { upcomingParties, daytimeParties, nighttimeParties } = useParties();
  return (
    <Layout>
      <Seo
        title={'既婚者合コン | 既婚者向け総合メディア - Cuddle Place'}
        description={'既婚者合コン・サークルの開催情報をまとめています。既婚者向け総合メディアCuddle Place（カドル プレイス）なら、セカンドパートナー、オープンマリッジ、婚外恋愛、専用マッチングアプリ、掲示板など、今の既婚者の出会いのリアルを追うことができます。'}
      />
      <Top />
      <Breadcrumbs />
      <SearchBox />
      <Upcoming upcomingParties={upcomingParties} />
      <Area />
      <ByDate />
      <ByTime daytimeParties={daytimeParties} nighttimeParties={nighttimeParties} />
      <SearchBox />
      <Service />
    </Layout>
  )
}

export default Parties
