import * as React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"

import Button from "@components/common/Button"
import CardLarge from "@components/common/CardLarge"

const TimeSection = props => {
  const {
    title,
    items,
    url
  } = props;
  return (
    <Container>
      <Title>{title}</Title>
      <div className="noScrollbar">
        <Cards>
          {items.map(elem => (
            <CardLarge
              {...elem}
              onClick={() => navigate(`/party/${elem.id}`)}
              key={elem.id}
            />
          ))}
        </Cards>
      </div>
      <Action>
        <Button
          variant='outlined'
          size='middle'
          font='small'
          width='60%'
          onClick={() => navigate(url)}
        >
          もっと見る
        </Button>
      </Action>
    </Container>
  )
}

const Container = styled.div`
  margin-top: 60px;
`;

const Cards = styled.div`
  display: flex;
  margin-top: 30px;
  width: 100%;
  overflow-x: auto;
`;

const Title = styled.h4`
  font-size: 18px;
  line-height: 1;
  color: #333333;
`;

const Action = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  padding-right: 24px;
`;

export default TimeSection
