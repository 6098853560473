import * as React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import { addDays, format } from 'date-fns'
import ja from "date-fns/locale/ja"

import Head from "@components/common/Head3"
import Button from "@components/common/Button"
import Card from "@components/common/Card"

const Upcoming = props => {
  const {
    upcomingParties
  } = props;
  const items = upcomingParties.map(elem => ({
    id: elem.id,
    title: elem.title,
    area: elem.area,
    startAt: `${format(new Date(elem.date), 'M/d(E)', { locale: ja })} ${elem.startAt.split(':')[0]}:${elem.startAt.split(':')[1]}〜`,
    src: `${elem.id.split('_')[0]}`,
  })).slice(0, 5);
  return (
    <Container>
      <Head
        main={`近日開催の合コン`}
        sub="Upcoming Party"
      />
      <Body>
        {items.map(elem => (
          <Card
            {...elem}
            titleComponent='h4'
            onClick={() => navigate(`/party/${elem.id}`)}
            key={elem.id}
          />
        ))}
      </Body>
      <Action>
        <Button
          variant='outlined'
          size='middle'
          font='small'
          width='75%'
          onClick={() => navigate(`/party-search/?type=upcoming&startDate=${format(new Date(), 'yyyy-MM-dd')}&endDate=${format(addDays(new Date(), 7), 'yyyy-MM-dd')}`)}
        >
          もっと見る
        </Button>
      </Action>
    </Container>
  )
}

const Container = styled.div`
  padding: 75px 24px;
`;

const Body = styled.div`
  margin-top: 50px;
`;

const Action = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 48px;
`;

export default Upcoming
