import * as React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Head from "@components/common/Head3"

const Area = () => {
  return (
    <Container>
      <Head
        main={`エリアで合コンを探す`}
        sub="by area"
      />
      <Body>
        <Image onClick={() => navigate(`/party-search/?type=area&area=札幌`)}>
          <StaticImage
            src="../../images/places/sapporo.png"
            alt="札幌"
          />
        </Image>
        <Image onClick={() => navigate(`/party-search/?type=area&area=仙台`)}>
          <StaticImage
            src="../../images/places/sendai.png"
            alt="仙台"
          />
        </Image>
        <Image onClick={() => navigate(`/party-search/?type=area&area=東京`)}>
          <StaticImage
            src="../../images/places/tokyo.png"
            alt="東京"
          />
        </Image>
        <Image onClick={() => navigate(`/party-search/?type=area&area=北関東`)}>
          <StaticImage
            src="../../images/places/kitakanto.png"
            alt="北関東"
          />
        </Image>
        <Image onClick={() => navigate(`/party-search/?type=area&area=静岡`)}>
          <StaticImage
            src="../../images/places/sizuoka.png"
            alt="静岡"
          />
        </Image>
        <Image onClick={() => navigate(`/party-search/?type=area&area=名古屋`)}>
          <StaticImage
            src="../../images/places/nagoya.png"
            alt="名古屋"
          />
        </Image>
        <Image onClick={() => navigate(`/party-search/?type=area&area=大阪`)}>
          <StaticImage
            src="../../images/places/osaka.png"
            alt="大阪"
          />
        </Image>
        <Image onClick={() => navigate(`/party-search/?type=area&area=岐阜`)}>
          <StaticImage
            src="../../images/places/gifu.png"
            alt="岐阜"
          />
        </Image>
        <Image onClick={() => navigate(`/party-search/?type=area&area=福岡`)}>
          <StaticImage
            src="../../images/places/fukuoka.png"
            alt="福岡"
          />
        </Image>
        <Image onClick={() => navigate(`/party-search/?type=area&area=沖縄`)}>
          <StaticImage
            src="../../images/places/okinawa.png"
            alt="沖縄"
          />
        </Image>
      </Body>
    </Container>
  )
}

const Container = styled.div`
  padding: 75px 24px;
`;

const Body = styled.div`
  width: 100%;
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Image = styled.div`
  width: 47.5%;
  margin-top: 5%;
  cursor: pointer;
`;

export default Area
