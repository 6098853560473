import * as React from "react"
import styled from "styled-components"
import RawBreadcrumbs from "@components/common/Breadcrumbs"

const arrBreadcrumbs = [
  {text: 'Top', to: '/'},
  {text: '既婚者合コン'},
];

const Breadcrumbs = () => (
  <Container>
    <RawBreadcrumbs data={arrBreadcrumbs}/>
  </Container>
)

const Container = styled.div`
  margin-bottom: 31px;
  margin-left: 17px;
`;

export default Breadcrumbs
