import * as React from "react"
import styled from "styled-components"

import PartyImage from "@components/common/PartyImage"

import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

const CardLarge = props => {
  const {
    title,
    area,
    startAt,
    createdAt,
    src
  } = props;
  return (
    <Container {...props}>
      <ImageWrapper>
        {src.includes('http')?
          <Image
            src={src}
            alt={area? 'Cuddle Place（カドル プレイス）の既婚者合コン・サークル':'Cuddle Place（カドル プレイス）のコラム'}
          />:
          <PartyImage
            src={src}
            alt={area? 'Cuddle Place（カドル プレイス）の既婚者合コン・サークル':'Cuddle Place（カドル プレイス）のコラム'}
            ImageStyle={ImageStyle}
          />
        }
      </ImageWrapper>
      <Content>
        <Title>{title}</Title>
        <Info>
          {area &&
            <Location>
              <LocationOnOutlinedIcon sx={IconStyle} />
              <Text style={{letterSpacing: '0.1em'}}>{area}</Text>
            </Location>
          }
          {startAt &&
            <StartAt>
              <CalendarMonthRoundedIcon sx={IconStyle} />
              <Text>{startAt}</Text>
            </StartAt>
          }
          {createdAt &&
            <CreatedAt>
              <Text>{createdAt}</Text>
            </CreatedAt>
          }
        </Info>
      </Content>
    </Container>
  )
}

const Container = styled.div`
  width: 190px;
  flex-shrink: 0;
  border: 1px solid #C4CDD5;
  border-radius: 8px;
  margin-right: 16px;
`;

const ImageWrapper = styled.div`
  width: 100%;
`;

const ImageStyle = {
  'borderRadius': '8px 8px 0px 0px',
  'width': '100%'
}

const Image = styled.img`
  border-radius: 8px 8px 0px 0px;
`;

const Content = styled.div`
  padding: 12px;
`;

const Title = styled.h5`
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.05em;
  color: #333333;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
`;

const Info = styled.div`
  display: flex;
  margin-top: 8px;
  margin-bottom: 4px;
`;

const Location = styled.div`
  display: flex;
  align-items: center;
`;

const IconStyle = {
  fontSize: '16px',
  color: '#999999',
  marginRight: '6px'
}

const StartAt = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
`;

const CreatedAt = styled.div`
`;

const Text = styled.span`
  font-size: 13px;
  line-height: 2;
  letter-spacing: 0em;
  color: #999999;
`;

export default CardLarge
