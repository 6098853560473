import * as React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

const Top = () => {
  return (
    <Container>
      <Body>
        <StaticImage
          src="../../images/parties_top.png"
          alt="既婚者向け総合メディア Cuddle Place (カドル プレイス)とは"
        />
        <Section>
          <Title>既婚者合コン</Title>
          <Text>
            エリアや趣味など、自分に合った合コン・サークルで素敵な出会いを探せます。
          </Text>
        </Section>
      </Body>
      <Dummy />
    </Container>
  )
}

const Container = styled.div`
`;

const Body = styled.div`
  position: relative;
`;

const Section = styled.div`
  width: 89%;
  background: #DBB34D;
  position: absolute;
  bottom: -45%;
  padding: 36px 24px;
`;

const Dummy = styled.div`
  padding-bottom: 27.12%;
  margin-bottom: 54px;
`;

const Title = styled.h1`
  font-weight: 600;
  font-size: 22px;
  line-height: 1.5;
  color: #FFFFFF;
  margin-bottom: 7px;
`;

const Text = styled.span`
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.08em;
  color: #FFFFFF;
`;

export default Top
