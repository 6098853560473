import * as React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"

import { byDateData } from "@enums/index";

import Head from "@components/common/Head3"

const Upcoming = () => {
  return (
    <Container>
      <Head
        main={`日付で合コンを探す`}
        sub="by Date"
      />
      <div className="noScrollbar">
        <Body>
          {byDateData.map((elem, index) => (
            <Card
              key={`byDateCard${index + 1}`}
              onClick={() => navigate(`/party-search/?type=date&startDate=${elem.value}&endDate=${elem.value}`)}
            >
              <DayOfWeek>{elem.day}</DayOfWeek>
              <Date>{elem.date}</Date>
            </Card>
          ))}
        </Body>
      </div>
    </Container>
  )
}

const Container = styled.div`
  padding: 75px 0px 75px 24px;
`;

const Body = styled.div`
  display: flex;
  margin-top: 50px;
  width: 100%;
  overflow-x: auto;
`;

const Card = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #000000;
  border-radius: 10px;
  width: 54px;
  height: 56px;
  margin-right: 12px;
  cursor: pointer;
`;

const DayOfWeek = styled.span`
  font-family: 'Noto Sans';
  font-size: 12px;
  line-height: 16px;
  color: #333333;
`;

const Date = styled.div`
  font-family: 'Noto Sans';
  font-size: 15px;
  line-height: 20px;
  color: #333333;
`;

export default Upcoming
