import * as React from "react"
import styled from "styled-components"

const Head = props => {
  const {
    main,
    sub
  } = props;
  return (
    <Container>
      <Main>{main}</Main>
      <Sub>{sub}</Sub>
    </Container>
  )
}

const Container = styled.div`
`;

const Main = styled.h3`
  font-size: 26px;
  line-height: 1.5;
  letter-spacing: 0.16em;
  color: #333333;
  white-space: pre-wrap;
`;

const Sub = styled.span`
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 0.1em;
  color: #DBB34D;
  margin-top: 2px;
`;

export default Head
