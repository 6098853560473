import * as React from "react";
import { Amplify, API, graphqlOperation } from "aws-amplify";
import { addDays, format } from "date-fns";

import awsmobile from "../aws-exports";
import { partyByAreaByDate } from "@graphql/queries";

Amplify.configure(awsmobile);

const upcomingPartiesPromise = async () => {
  try {
    const startDate = format(new Date(), "yyyy-MM-dd");
    const endDate = format(addDays(new Date(), 7), "yyyy-MM-dd");
    const res = await API.graphql(
      graphqlOperation(partyByAreaByDate, {
        area: "東京",
        date: { between: [startDate, endDate] },
        filter: {
          maleVacancy: { eq: true },
          femaleVacancy: { eq: true },
          endAt: { ne: "" }
        },
        limit: 1000
      })
    );
    const items = res.data.partyByAreaByDate.items;
    return {
      type: "upcomingParties",
      items: items
    };
  } catch (err) {
    console.error(err);
    throw new Error("something went wrong");
  }
};

const daytimePartiesPromise = async () => {
  try {
    const startDate = format(addDays(new Date(), 7), "yyyy-MM-dd");
    const endDate = format(addDays(new Date(), 21), "yyyy-MM-dd");
    const res = await API.graphql(
      graphqlOperation(partyByAreaByDate, {
        area: "東京",
        date: { between: [startDate, endDate] },
        filter: {
          maleVacancy: { eq: true },
          femaleVacancy: { eq: true },
          endAt: { ne: "" },
          and: [{ endAt: { le: "16:00:00" } }]
        },
        limit: 1000
      })
    );
    const items = res.data.partyByAreaByDate.items;
    return {
      type: "daytimeParties",
      items: items
    };
  } catch (err) {
    console.error(err);
    throw new Error("something went wrong");
  }
};

const nighttimePartiesPromise = async () => {
  try {
    const startDate = format(addDays(new Date(), 7), "yyyy-MM-dd");
    const endDate = format(addDays(new Date(), 21), "yyyy-MM-dd");
    const res = await API.graphql(
      graphqlOperation(partyByAreaByDate, {
        area: "東京",
        date: { between: [startDate, endDate] },
        filter: {
          maleVacancy: { eq: true },
          femaleVacancy: { eq: true },
          endAt: { ne: "" },
          and: [{ startAt: { ge: "16:00:00" } }]
        },
        limit: 1000
      })
    );
    const items = res.data.partyByAreaByDate.items;
    return {
      type: "nighttimeParties",
      items: items
    };
  } catch (err) {
    console.error(err);
    throw new Error("something went wrong");
  }
};

const useParties = () => {
  const [upcomingParties, setUpcomingParties] = React.useState([]);
  const [daytimeParties, setDaytimeParties] = React.useState([]);
  const [nighttimeParties, setNighttimeParties] = React.useState([]);
  React.useEffect(() => {
    (async () => {
      const res = await Promise.all([
        upcomingPartiesPromise(),
        daytimePartiesPromise(),
        nighttimePartiesPromise()
      ]);
      for (const target of res) {
        if (target.type === "upcomingParties") setUpcomingParties(target.items);
        if (target.type === "daytimeParties") setDaytimeParties(target.items);
        if (target.type === "nighttimeParties")
          setNighttimeParties(target.items);
      }
    })();
  }, []);
  return { upcomingParties, daytimeParties, nighttimeParties };
};

export default useParties;
