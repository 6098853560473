import * as React from "react"
import styled from "styled-components"
import { format } from 'date-fns'
import ja from "date-fns/locale/ja"

import Head from "@components/common/Head3"
import TimeSection from "@components/parties/TimeSection"

const ByTime = props => {
  const {
    daytimeParties,
    nighttimeParties
  } = props;
  const nightItems = nighttimeParties.map(elem => ({
    id: elem.id,
    title: elem.title,
    area: elem.area,
    startAt: `${format(new Date(elem.date), 'M/d(E)', { locale: ja })}`,
    src: `${elem.id.split('_')[0]}`,
  })).slice(0, 5);
  const dayItems = daytimeParties.map(elem => ({
    id: elem.id,
    title: elem.title,
    area: elem.area,
    startAt: `${format(new Date(elem.date), 'M/d(E)', { locale: ja })}`,
    src: `${elem.id.split('_')[0]}`,
  })).slice(0, 5);
  return (
    <Container>
      <Head
        main={`時間帯で合コンを探す`}
        sub="by Time"
      />
      <TimeSection title="夜開催の合コン" items={nightItems} url={`/party-search/?type=night&startTime=16:00`} />
      <TimeSection title="昼開催の合コン" items={dayItems} url={`/party-search/?type=daytime&endTime=16:00`} />
    </Container>
  )
}

const Container = styled.div`
  padding: 75px 0px 75px 24px;
`;

export default ByTime
