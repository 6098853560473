import * as React from "react";
import styled from "styled-components";
import { navigate } from "gatsby";
import queryString from "query-string";

import { sexData, ageData, areaData, serviceNameData } from "@enums";

import SearchDetail from "@components/common/SeachDetail";
import Button from "@components/common/Button";
import MuiButton from "@mui/material/Button";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import Radio from "@components/common/Radio";
import Select from "@components/common/Select";

const SearchBox = () => {
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [sex, setSex] = React.useState("female");
  const [age, setAge] = React.useState("");
  const [area, setArea] = React.useState("");
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [startTime, setStartTime] = React.useState(null);
  const [endTime, setEndTime] = React.useState(null);
  const [serviceName, setServiceName] = React.useState(
    serviceNameData.map(elem => elem.value)
  );
  const searchData = {
    sex: sex,
    setSex: setSex,
    age: age,
    setAge: setAge,
    area: area,
    setArea: setArea,
    startDate: startDate,
    setStartDate: setStartDate,
    endDate: endDate,
    setEndDate: setEndDate,
    startTime: startTime,
    setStartTime: setStartTime,
    endTime: endTime,
    setEndTime: setEndTime,
    serviceName: serviceName,
    setServiceName: setServiceName
  };
  const clickSearch = () => {
    const parsed = queryString.parse();
    parsed.type = "condition";
    sex === "male"
      ? (parsed.maleVacancy = "true")
      : (parsed.femaleVacancy = "true");
    if (age) parsed.age = age;
    if (area) parsed.area = area;
    if (startDate) parsed.startDate = startDate;
    if (endDate) parsed.endDate = endDate;
    if (startTime) parsed.startTime = startTime;
    if (endTime) parsed.endTime = endTime;
    if (serviceName.length > 0) parsed.serviceName = serviceName.join(",");
    const stringified = queryString.stringify(parsed);
    navigate(`/party-search/?${stringified}`);
  };
  return (
    <Container>
      <Title>新しい出会いを探してみましょう</Title>
      <SubTitle>あなたの年齢・性別・エリアを選択</SubTitle>
      <Section>
        <Radio value={sex} setValue={setSex} form={sexData} />
        <Select
          value={age}
          setValue={setAge}
          label="年齢"
          form={ageData}
          width="92px"
        />
      </Section>
      <Select
        value={area}
        setValue={setArea}
        label="開催地を選択"
        sx={{ marginBottom: "45px" }}
        form={areaData}
      />
      <Button
        variant="contained"
        size="small"
        font="medium"
        width="75%"
        icon={true}
        onClick={clickSearch}
      >
        この条件で探す
      </Button>
      <MuiButton
        startIcon={<AddSharpIcon fontSize="small" />}
        sx={{ marginTop: "5px", marginBottom: "5px" }}
        onClick={() => setOpenDrawer(true)}
      >
        こだわりを追加
      </MuiButton>
      <SearchDetail
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        data={searchData}
        clickSearch={clickSearch}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 33px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f8f7f1;
`;

const Title = styled.h2`
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 0.16em;
  color: #333333;
  margin-bottom: 20px;
`;

const SubTitle = styled.h3`
  font-size: 16px;
  line-height: 1.5;
  color: #333333;
  margin-bottom: 20px;
`;

const Section = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export default SearchBox;
